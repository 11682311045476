/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// custom styling for tabs(globally)
.mat-mdc-tab-header {
	border-bottom: none !important;

	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
	.mat-mdc-tab-link-container {
		padding: 0 24px;

		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
		.mat-mdc-tab-list {
			.mat-mdc-tab-links {
				.mat-mdc-tab-link {
					min-width: 0 !important;
					height: 40px !important;
					padding: 0 20px !important;
					border-radius: 9999px !important;
					@apply text-secondary;

					/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					&.mdc-tab--active {
						border: none;
						@apply bg-gray-700 bg-opacity-10 dark:bg-gray-50 dark:bg-opacity-10 #{'!important'};
						@apply text-default #{'!important'};
					}

					// /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					// + .mat-tab-label {
					//     margin-left: 4px;
					// }
					// /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					// .mat-tab-label-content {
					//     line-height: 20px;
					// }
					/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					&:not(.mat-mdc-tab-disabled) {
						.mdc-tab-indicator__content--underline {
							border-color: transparent !important;
						}
					}

					/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					.mdc-tab__content {
						.mdc-tab__text-label {
							--tw-text-opacity: 0.5 !important;
							color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
						}
					}

					/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
					&:hover,
					&:active {
						border-radius: 9999px !important;

						.mdc-tab__ripple {
							background-color: transparent !important;

							&::before {
								border-radius: 9999px !important;
								opacity: 0 !important;
							}
						}

						/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
						.mat-mdc-tab-ripple {
							border-radius: 9999px !important;
							display: none;
						}
					}
				}
			}
		}
	}

	::ng-deep .mat-mdc-ink-bar {
		display: none !important;
	}
}

//custom select - without border, background transparent, dropdown
.mat-mdc-form-field.custom-select {
	&.mat-form-field-appearance-fill {
		.mat-mdc-text-field-wrapper {
			@apply bg-transparent shadow-none border-0 #{'!important'};
		}
	}
}

//input slider disabled globally
.mat-mdc-slider {
	input[disabled] {
		opacity: 0;
		pointer-events: none;
	}
}

//slider count input value boder
.slider-count-wrapper {
	.mdc-text-field {
		box-shadow: none !important;
		border: none !important;

		.mat-mdc-form-field-flex {
			padding: 0 !important;

			.mat-mdc-form-field-infix {
				width: auto !important;
			}

			.mat-mdc-form-field-input-control {
				text-align: right;
			}
		}
	}

	.mat-mdc-form-field-bottom-align {
		display: none;
	}
}

//customized slider color/size
.mat-mdc-slider {
	//Thumb-track thumb-knob size
	.mdc-slider__thumb-knob {
		width: var(--mdc-slider-handle-width, 10px) !important;
		height: var(--mdc-slider-handle-height, 10px) !important;
		border-width: calc(var(--mdc-slider-handle-height, 10px) / 2) calc(var(--mdc-slider-handle-width, 10px) / 2) !important;
	}

	//Thumb-knob active background color
	.mdc-slider__thumb-knob {
		background-color: #1e293b !important;
		border-color: #1e293b !important;
	}

	//Thumb-track fill active background color
	.mdc-slider__track--active_fill {
		border-color: #1e293b !important;
	}

	//Thumb-track fill inactive background color
	.mdc-slider__track--inactive {
		background-color: #cbd5e1 !important;
		opacity: 1 !important;
	}

	// track size, position active/inactive state
	.mdc-slider__track--inactive,
	.mdc-slider__track--active {
		height: var(--mdc-slider-inactive-track-height, 2px) !important;
		top: calc((var(--mdc-slider-inactive-track-height, 5px) - var(--mdc-slider-active-track-height, 4px)) / 2) !important;
	}

	// slider disabled state styling
	&.mdc-slider--disabled {
		opacity: 1 !important;

		//Thumb-track fill active background color
		.mdc-slider__track--active_fill {
			border-color: #cbd5e1 !important;
		}

		//Thumb-knob active background color
		.mdc-slider__thumb-knob {
			background-color: #cbd5e1 !important;
			border-color: #cbd5e1 !important;
			box-shadow: var(
				--mdc-slider-handle-elevation,
				0px 0px 0px 2px rgba(255, 255, 255, 1),
				0px 0px 0px 2px rgb(255 255 255),
				0px 1px 3px 0px rgb(255 255 255)
			) !important;
		}

		//Thumb-track fill inactive background color
		.mdc-slider__track--inactive {
			background-color: #cbd5e1 !important;
			opacity: 1 !important;
		}
	}
}

//chip-set styling
// background color
.mat-mdc-standard-chip {
	&:not(.mdc-evolution-chip--disabled) {
		background-color: #e2e8f0 !important;

		.mdc-evolution-chip__icon--trailing {
			color: #1e293b !important;
			opacity: 0.4;
		}
	}
}

.mat-mdc-form-field {
	&.mat-form-field-appearance-fill {
		.mat-mdc-text-field-wrapper {
			.mat-mdc-form-field-flex {
				.mat-mdc-form-field-infix {
					.mat-mdc-chip-set {
						margin: 0 auto;
					}
				}
			}
		}
	}
}

//toggle button styling
.mat-slide-toggle-bar {
	background-color: #94a3b8;
}

.mat-mdc-slide-toggle {
	.mdc-switch {
		&.mdc-switch--selected {
			&:enabled .mdc-switch__icon {
				display: none !important;
			}
		}

		&.mdc-switch--unselected {
			&:enabled .mdc-switch__icon {
				display: none !important;
			}

			&:enabled .mdc-switch__handle {
				&:after {
					background: #fafafa !important;
				}
			}
		}

		&:enabled .mdc-switch__track {
			&:before {
				background: #94a3b8 !important;
			}
		}
	}
}

//validation message hide if there is no message
.unwanted-bottom-margin-remove {
	.mat-mdc-form-field-bottom-align {
		display: none;
	}
}

//Slider with custom thumb - fixed opacity
.mat-mdc-slider {
	.mdc-slider__value-indicator {
		opacity: 1 !important;
	}
}

// styling for ais search - help center component page
.ais-search-wrapper {
	.ais-SearchBox-form,
	.ais-SearchBox-input {
		@apply rounded-lg shadow-none h-[44px];
	}
	//svg - cross button size
	svg {
		@apply h-[14px] w-[14px];
	}
}
